button,
.paper-btn,
[type='button'] {
  @include border-style();
  @include shadow();
  @include transition();
  align-self: center;
  background: transparent;
  border: 2px solid $primary;
  color: $primary;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  outline: none;
  padding: 0.75rem;
  font-weight: bold;

  @include resp(520px) {
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 1rem;
    text-align: center;
  }

  &.btn-large {
    @include shadow(large);
    font-size: 2rem;
    padding: 1rem;
  }

  &.btn-small {
    @include shadow(small);
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &:hover {
    @include shadow(hover);
    border: 2px solid $secondary-dark;
    color: $primary;
  }

  &:focus {
    // border: 2px solid $primary;
    box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, 0.3);
  }

  &:active {
    border: 2px solid $primary;
    // border-color: hsla(0, 0%, 0%, 0.2);
    transition: none;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a {
  background-image: none;
  text-decoration: underline;

  &:visited {
    color: $primary;
    text-decoration: none;
  }

  &:link {
    color: $primary;
  }

  &:hover {
    color: $secondary-dark;
  }

  &.noHoverChange {
    text-decoration: none;
  }
  &.noHoverChange:hover {
    color: $primary;
  }
}



@each $colorName, $color, $color-light, $color-text in $colors {
  .alert-#{$colorName} {
    background-color: $color-light;
    border-color: $color;
    color: $color;
  }

  button.btn-#{$colorName},
  .paper-btn.btn-#{$colorName},
  [type='button'].btn-#{$colorName} {
    background-color: $color-light;
    border-color: $color;
    color: $color-text;

    &:hover {
      &:active {
        background-color: darken($color-light, 10%);
      }
    }
  }
}
