.tabs {
  .content {
    display: none;
    padding: 0.75rem 0 0;
  }

  input {
    display: none;

    &:checked+label {
      border-bottom: solid 3px $secondary;
      color: $primary;
    }

    @for $num from 1 through 5 {
      &[id='tab#{$num}']:checked~div[id='content#{$num}'] {
        display: block;
      }
    }
  }

  label {
    color: $primary-light;
    display: inline-block;
    font-weight: 600;
    margin: 0 0 -1px;
    padding: 0.75rem;
    text-align: center;

    &:hover {
      color: $muted;
      cursor: pointer;
    }
  }
}
