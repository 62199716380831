.shadow {
  @include shadow();

  &.shadow-large {
    @include shadow(large);
  }

  &.shadow-small {
    @include shadow(small);
  }

  &.shadow-hover {
    &:hover {
      @include shadow(hover);
    }
  }
}

.child-shadows > * {
  @include shadow();
}

.child-shadows-hover > * {
  @include shadow();

  &:hover {
    @include shadow(hover);
  }
}
