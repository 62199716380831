html {
  background-color: #d5e5d5;
  font-weight: bold;
}
body {
  // padding: 1rem;
}
a {
  background-image: none;
}
a:hover {
  color: $secondary-dark;
}