@font-face {
    font-family: 'windsongregular';
    src: local('Windsong-webfont'), url('Windsong-webfont.woff') format('woff');
    /* src: url('Windsong-webfont.eot');
    src: url('Windsong-webfont.eot?#iefix') format('embedded-opentype'),
         url('Windsong-webfont.woff2') format('woff2'),
         url('Windsong-webfont.woff') format('woff'),
         url('Windsong-webfont.ttf') format('truetype'),
         url('Windsong-webfont.svg#windsongregular') format('svg'); */
    font-weight: normal;
    font-style: normal;

}

