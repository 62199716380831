html {
  color: $font-color;
  font-family: $body-font, cursive;
  font-size: $global-font-size;
}

p,
a,
button,
table,
thead,
tbody,
th,
tr,
td,
input,
textarea,
select,
option {
  font-family: $body-font, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font, sans-serif;
  margin: none;
  padding: 0.67rem 0 0 0;
}

h1 {
  font-size: 4rem;

  @include resp(small) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 3rem;

  @include resp(small) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 2rem;

  @include resp(small) {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.5rem;

  @include resp(small) {
    font-size: 1rem;
  }
}

h5 {
  font-size: 1rem;

  @include resp(small) {
    font-size: 0.8rem;
  }
}

h6 {
  font-size: 0.8rem;

  @include resp(small) {
    font-size: 0.7rem;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
